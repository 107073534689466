<template>
  <div v-if="carteForm">
    <b-card class="card" :header="carteId ? 'Upraviť' : 'Vytvoriť novú kategóriu'">
      <b-button @click="onClose" variant="secondary" class="close-button">×</b-button>
      <template v-for="(field, i) in fields">
        <b-form-group :label="field.title" :label-for="field.name" :key="i">
          <b-form-input :ref="'focusInput'" :id="field.name" :value="carteForm[field.name]" @input="fillField($event, field.name)" :state="validateState(field.name)" type="text"/>
          <b-form-invalid-feedback>{{field.warning}}</b-form-invalid-feedback>
        </b-form-group>
      </template>
      <b-button @click="onSubmit">Uložiť</b-button>
    </b-card>
  </div>
</template>
<script>
import Vue from 'vue'
import {mapActions, mapGetters} from "vuex"
import { required } from "vuelidate/lib/validators"

export default Vue.extend({
  props: ['carteId'],
  data: function () {
    return {
      carteForm: null,
      fields: [
        { name: 'name', title: 'Názov kategórie', warning: 'Toto pole je povinné a musí obsahovať aspoň 3 znaky.'},
      ]
    }
  },
  computed: {
    ...mapGetters('carte', ['carte'])
  },
  mounted() {
    if(this.carteId)
      this.carteForm = {... this.carte.find(v => v.id === this.carteId)}
    else
      this.carteForm = {name : ""}

    this.$nextTick(_ => this.$refs.focusInput[0].focus())
  },
  validations: {
    carteForm: {
      name: {required}
    }
  },
  methods: {
    ...mapActions('carte', ['updateCarteInfo']),
    fillField($e, field) {
      this.carteForm[field] = $e
      this.$v.carteForm[field].$touch()
      this.$forceUpdate()
    },

    validateState(field) {
      if(!this.$v.carteForm[field])
        return null

      const { $dirty, $error } = this.$v.carteForm[field]
      return $dirty ? !$error : null
    },
    onClose: function() {
      this.$emit('close')
    },

    onSubmit: async function () {
      this.$v.carteForm.$touch()
      if (this.$v.carteForm.$anyError)
        return

      this.$v.carteForm.$reset()
      this.$wToast.clear_loading()

      if (this.carteId)
        this.carteForm.id = this.carteId

      await this.updateCarteInfo(this.carteForm)
      this.$emit('close')
    }
  }
})
</script>

<style lang="scss" scoped>
.close-button {
  color: #C4C4C4;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 23px;
  right: 30px;
  font-size: 24px;
  border: none;
  font-weight: 300;
}
</style>
